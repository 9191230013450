import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const PageLocationContext = createContext({})

export const PageLocationProvider = ({ children, location }) => (
  <PageLocationContext.Provider value={location}>
    {children}
  </PageLocationContext.Provider>
)

PageLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const usePageLocation = () => {
  const value = useContext(PageLocationContext)
  return value
}
