import createNerdwalletAnalytics from "@nerdwallet/analytics/createNerdwalletAnalytics"
import { getCookie } from "@src/hooks/useCookie"

const CLIENT_ID = "smb-fundera-gatsby"
const QUERY0_URL = "https://www.nerdwallet.com/api/query0/"
const DEPLOYABLE_VERSION = "1"

class Analytics {
  constructor({ dryRun, environment }) {
    this.dryRun = dryRun
    this.environment = environment

    this.analytics = createNerdwalletAnalytics({
      enabled: true,
      appConfig: {
        CLIENT_ID,
        API: {
          BASE_URLS: {
            QUERY0: QUERY0_URL,
          },
        },
        _meta: {
          // The environment is set as "prod" under tracking scenarios, too,
          // but uses a different "_meta" here
          environment: environment === "prod" ? "production" : environment,
        },
      },
    })
    this.analytics.setEnabled(true)
  }

  async track(eventType, payload) {
    const updatedPayload = {
      ...payload,
      frame: payload.frame || this.getFrame(),
    }

    if (this.dryRun) {
      console.info("[Analytics] - DRY RUN - Tracking called with", {
        eventType,
        payload: updatedPayload,
      })
      return // `analytics.track` returns `undefined`, as well
    }
    return this.analytics.track(eventType, updatedPayload)
  }

  async trackPage({ url, referrer, innerHeight, innerWidth }) {
    try {
      // must fire user navigated FIRST so that navigation ID gets set globally on analytics object
      await this.track("user_navigated", {
        url,
        web_referrer: referrer,
        viewport_height: innerHeight,
        viewport_width: innerWidth,
      })
    } catch (err) {
      console.error("Error when attempting to process page analytics", err)
    }
  }

  getState(...args) {
    return this.analytics.getState(...args)
  }

  getFrame() {
    return {
      deployable_name: CLIENT_ID,
      deployable_environment: this.environment,
      deployable_version: DEPLOYABLE_VERSION,
      visitor_identity_assertion_token: getCookie(
        "visitor_identity_assertion_token"
      ),
      device_id: getCookie("cookie_id"),
    }
  }
}

/**
 * Create an instance of analytics used for page view tracking, etc
 *
 * @param {Object} options instance options
 * @param {boolean} options.dryRun true to log and skip actual tracking calls
 * @param {String} options.environment tracking environment identifier
 * @returns {Analytics} instance
 */
export const create = options => {
  return new Analytics(options)
}
