import { PRODUCT_TYPES } from "@nerdwallet/nw-api-sdk/marketplace"

export const version = "5.0"
/*
ATTN: OLD_NAV_HEADER_HEIGHT should mirror @levels-height in src/lib/constants.js

If you change any values here, make sure you change them there too.
*/
export const OLD_NAV_HEADER_HEIGHT = 40
export const FUNDERA_APPLY_URL = "https://www.fundera.com/apply-for-a-loan"
export const NERDWALLET_APPLY_URL =
  "https://nerdwallet.fundera.com/apply-for-a-loan"
export const FLOW_INSURANCE = "application_flow=insurance"
export const FLOW_PAYMENTS = "application_flow=payments"
export const PARTNER_AID = "nerdwallet"
export const PARTNER_PARAM = `aid=${PARTNER_AID}`
export const SIGN_FOR_TOPICS = ["Small Business Loans", "General SMB"]
export const SIGN_UP_TEXT = "See Your Loan Options"
export const SIGN_FOR_INSURANCE = "See Your Options"
export const FUNDERA_BY_NERDWALLET = "with Fundera by NerdWallet"
export const INSURANCE_TOPICS = ["Business Insurance"]
export const INSURANCE_CTA_TEXT = "Find a provider"
export const INSURANCE_CTA_URL = `${NERDWALLET_APPLY_URL}?${PARTNER_PARAM}&${FLOW_INSURANCE}`
export const PAYMENTS_TOPICS = ["Business Software"]
export const PAYMENTS_CTA_TEXT = "Get Started"
export const PAYMENTS_CTA_URL = `${NERDWALLET_APPLY_URL}?${PARTNER_PARAM}&${FLOW_PAYMENTS}`
export const COMPARE_CTA_TEXT = "Compare"
export const COMPARE_CTA_TYPE = "Compare CTA"
export const COMPARE_CTA_URL =
  "https://www.nerdwallet.com/business-credit-cards"
export const TOOLTIP_TEXT = {
  [PRODUCT_TYPES.BANKING]:
    "NerdWallet's ratings are determined by our editorial team. " +
    "The scoring formulas take into account multiple data points for each financial product and service.",
  [PRODUCT_TYPES.CREDIT_CARDS]:
    "NerdWallet's ratings are determined by our editorial team. " +
    "The scoring formula takes into account the type of card being reviewed " +
    "(such as cash back, travel or balance transfer) and the card's rates, fees, rewards and other features.",
}
export const REVIEW_CTA_TYPE = "Read Review"
export const EMAIL_CTA_TYPE = "CC Email Me CTA"
export const SUMMARY_CTA_TYPE = "Summary CTA"
export const RATES_AND_FEES_CTA_TYPE = "RATES_FEES"
export const DEFAULT_SEO_IMAGE =
  "https://www.nerdwallet.com/assets/img/nw-logos/NW-default_og-image.jpg"
