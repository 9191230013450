import React, { useState } from "react"
import PropTypes from "prop-types"

import { useEventListener } from "@src/hooks/useEventListener"

import { AnalyticsContext } from "@nerdwallet/analytics/react"

export const AnalyticsProvider = ({ children }) => {
  const [analyticsInstance, setAnalyticsInstance] = useState(null)
  useEventListener("nw:analyticsloaded", ({ detail: { analytics } }) => {
    setAnalyticsInstance(analytics)
  })

  return (
    <AnalyticsContext.Provider value={analyticsInstance}>
      {children}
    </AnalyticsContext.Provider>
  )
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node,
}
