import "@nerdwallet/base-styles/reset.css"
import "@nerdwallet/base-styles/base.css"
import "./src/global.less"

import React from "react"
import { create as createAnalytics } from "@src/lib/analytics"
import { isFlagActiveOnUrl } from "@src/lib/helpers"
import { AnalyticsProvider } from "@src/analytics/AnalyticsContext"
import { PageLocationProvider } from "@src/hooks/usePageLocation"

// For testing only: Exposing the function that bootstraps NWJS
// in production so we can test sending events. This also ensures
// the tracking pixel has fired before use.
//
function initNerdwalletAnalytics() {
  if (window.nwAnalyticsDidInit) {
    return false
  }

  window.nwAnalyticsDidInit = true // flag to ensure script does not get added to DOM more than once.

  const dryRun = !isProd() && !isFlagActiveOnUrl("nwanalytics")
  const img = document.createElement("img")

  img.onload = function () {
    const analytics = createAnalytics({
      environment: isProd() ? "prod" : "stage",
      dryRun,
    })
    const event = new CustomEvent("nw:analyticsloaded", {
      detail: { analytics },
    })
    window.dispatchEvent(event)
  }

  const src = dryRun
    ? "/tracking/nw-dummy-pixel.gif"
    : "https://www.nerdwallet.com/blog/wp-content/themes/nerdwallet/assets/tracking/nw-pixel-v1.gif"

  img.alt = ""
  img.src = src
  img.width = "1"
  img.height = "1"

  document.body.appendChild(img)
}

function initGTM() {
  if (window.gtmDidInit) {
    return false
  }

  window.dataLayer = window.dataLayer || []
  window.gtmDidInit = true // flag to ensure script does not get added to DOM more than once.

  var script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true
  script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-5LDM3M8"

  script.onload = function () {
    window.dataLayer.push({
      // ensure PageViews are always tracked
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
      "gtm.uniqueEventId": 0,
    })
  }

  document.head.appendChild(script)
}

function isProd() {
  return window.location.hostname.includes("nerdwallet")
}

export const onClientEntry = function () {
  if (isProd() || isFlagActiveOnUrl("gtm")) {
    initGTM()
  }
}

export const onRouteUpdate = function () {
  initNerdwalletAnalytics()
}

export const wrapPageElement = ({ element, props }) => {
  const { location } = props

  return (
    <PageLocationProvider location={location}>{element}</PageLocationProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <AnalyticsProvider>{element}</AnalyticsProvider>
}
