import React from "react"
import { stringify } from "querystring"

import { PRODUCT_TYPES } from "@nerdwallet/nw-api-sdk/marketplace"
import { INSURANCE_TOPICS, PAYMENTS_TOPICS } from "@src/lib/constants"

const DEVICE_TYPES = ["android", "iphone"]

export const isFlagActiveOnUrl = flag => {
  try {
    return window.location.search.includes(flag + "=true")
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isDevice = type => {
  if (!DEVICE_TYPES.includes(type)) {
    throw new Error(`Unrecognized device: ${type}`)
  }

  return (
    typeof window !== "undefined" &&
    window?.navigator?.userAgent?.toLowerCase()?.includes(type)
  )
}

/*
 * Introduces an artificial delay before an event happens to help prevent unintentional user action (i.e. briefly mousing over an
 * icon the user doesn't mean to interact with). If a user triggers a different event before the timeout is hit, the first action is
 * cancelled.
 */
export const useInteractionDelay = (
  { initialDelay, subsequentDelay } = {
    initialDelay: 100,
    subsequentDelay: 300,
  }
) => {
  const timeoutRef = React.useRef(null)

  const delay = (callback, openedTab) => {
    // if no tabs are hovered, activate the hover in 0.1 seconds. if a hover state is already active,
    // wait 0.5 seconds before switching hovers
    const timeout = openedTab === null ? initialDelay : subsequentDelay
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      callback()
      timeoutRef.current = null
    }, timeout)
  }

  const cancel = () => {
    clearTimeout(timeoutRef.current)
  }

  return [delay, cancel]
}

// TODO how to move this to the "server" render, instead of the client?
// We do it on fundera-ledger by fetching the data before the "createPages",
// but it looks like this is not available on page queries
const getApplyUrl = section => {
  const qs =
    section.categoryName === "Top SBA Lenders"
      ? "top_sba=true"
      : `requested_lender=${encodeURIComponent(
          section.marketplaceEntity.institution.name
        )}`

  return `https://www.fundera.com/apply-for-a-loan?aid=nerdwallet&${qs}`
}

const getNerdWalletRedirectUrl = section => {
  const link = section?.marketplaceEntity?.cta?.link
  const qs =
    section.categoryName === "Top SBA Lenders"
      ? { top_sba: true }
      : {
          requested_lender: encodeURIComponent(
            section.marketplaceEntity.institution.name
          ),
        }

  return link
    ? attachQueryString(link, { ...qs, finish_type: "external_application" })
    : link
}

export const getApplyOrRedirectUrl = section =>
  getNerdWalletRedirectUrl(section) || getApplyUrl(section)

export const attachQueryString = (path, params) => {
  const serialized = stringify(params)
  const separator = path.includes("?") ? "&" : "?"
  return serialized.length ? `${path}${separator}${serialized}` : path
}

export const isSectionActive = section =>
  section.marketplaceEntity.status === "ACTIVE"

const isPaymentsTopic = attributionTopic =>
  PAYMENTS_TOPICS.includes(attributionTopic)

export const isInsuranceTopic = attributionTopic =>
  INSURANCE_TOPICS.includes(attributionTopic)

export const getTopic = attributionTopic => {
  if (isInsuranceTopic(attributionTopic)) return "insurance"

  if (isPaymentsTopic(attributionTopic)) return "payments"

  return "lending"
}

export const getLendingBestFor = ({ type, maxFundingFormatted }) => {
  if (type === "Lines of credit") {
    return `Best for Business lines of credit up to ${maxFundingFormatted}`
  }

  return `Best for ${type}`
}

export const formatRating = unformattedValue =>
  new Intl.NumberFormat(
    {},
    {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }
  ).format(unformattedValue)

export const formatCurrency = unformattedValue =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(unformattedValue)

const TOPICS_WITH_BANNER = [
  process.env.GATSBY_FF_USE_BANNER_FOR_SMB === "true" && "Small Business Loans",
  "General SMB",
  "Business Insurance",
]

export const shouldShowApplyBanner = attributionTopic => {
  return TOPICS_WITH_BANNER.includes(attributionTopic)
}

export const getProductType = entity =>
  entity.product?.productType || entity.productType

export const getLearnMoreLink = entity =>
  entity.reviewLink || entity.institution?.reviewLink

export const isCreditCardProduct = entity =>
  getProductType(entity) === PRODUCT_TYPES.CREDIT_CARDS

export const isSmallBusinessLoanProduct = entity =>
  getProductType(entity) === PRODUCT_TYPES.SMB_LOANS

export const getAbsoluteURL = pathname =>
  `https://www.nerdwallet.com${pathname}`

/**
 * Get the canonical link for the given gatsby page entry
 *
 * @param {Object} page page entry
 * @param {string} page.link link retrieved from query0
 * @param {string} [page.customPath] custom path used for the page, if any
 * @returns {string} canonical link
 */
export const getCanonicalLink = page =>
  page.customPath ? getAbsoluteURL(page.customPath) : page.link

export const getDisplayName = Component =>
  Component.displayName || Component.name || "Component"

// Inspired on selectors approach: https://github.com/NerdWallet/structured-content/blob/99d961c595059ab907394ca646b8ca5efade53cc/src/components/mini-product-card/index.jsx#L11-L25
export const getStarRating = marketplaceEntity => {
  return (
    marketplaceEntity.starRating ||
    marketplaceEntity.product?.institution?.starRating ||
    marketplaceEntity.institution?.starRating
  )
}

export const slugify = string => {
  return String(string).toLowerCase().replace(/\W+/g, "_")
}

/**
 * Create a sorting function that sorts in the field order given by configs
 *
 * @param {Object} fieldToValueMapping field name to value getter mapping
 * @returns {Function} function that will retrieve the sorting approach
 */
export const makeSortFunction = fieldToValueMapping => {
  /**
   * Get function that will ultimately sort when passed to Array.sort
   *
   * @param {Object[]} configs array of config objects that indicate how to sort
   * @param {string} configs[].field field to use for sorting
   * @returns {Function} function that sorts the array
   */
  const sortFunction = configs => {
    return (i, j) => {
      const [{ field, asc = true }, ...rest] = configs

      const returns = asc ? [1, -1] : [-1, 1]
      const getValue = fieldToValueMapping[field]
      const vi = getValue(i)
      const vj = getValue(j)

      if (vi === vj) {
        const sort = sortFunction(rest)
        return sort(i, j)
      }

      return vi > vj ? returns[0] : returns[1]
    }
  }
  return sortFunction
}

/**
 * Debounce the function `f` by `timeout` amount
 *
 * @param {Functio} f function to debounce
 * @param {number} timeout timeout after which the function is called
 * @returns {Function} debounced function
 */
export const debounce = (f, timeout) => {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      f(...args)
    }, timeout)
  }
}

/**
 * Check if `monetizable` field value corresponds to a monetizable value
 *
 * @param {string} monetizable field value
 * @returns {boolean} true if monetizable includes "yes"
 */
export const matchesMonetizable = monetizable =>
  monetizable?.toLowerCase().includes("yes")
