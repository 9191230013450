export const getCookie = cookieName => {
  // This is not a hook - it is not connected to react in any way.
  // So, we can use it on function or class components (not only
  // on function-components).
  if (typeof document === "undefined") return null
  const cookie = document.cookie
    .split("; ")
    .find(row => row.startsWith(`${cookieName}=`))
    ?.split("=")[1]
  return cookie
}
